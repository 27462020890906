import React, { FC, useState, useEffect } from 'react';
import Layouts from '../../components/layout';
import PageHeader from '../../components/pagesComponents/WhatWeDo/PageHeader';
import SmallCompanyContainer from '../../components/pagesComponents/MainPage/SmallCompanyContainer';
import LargeCompanyContainer from '../../components/pagesComponents/MainPage/LargeCompanyContainer';
import TechIcon from '../../components/pagesComponents/MainPage/TechIcon';
import walmart from '../../assets/img/companies/walmart.svg';
import spectar from '../../assets/img/companies/spectar.svg';
import statflo from '../../assets/img/companies/statflo.svg';
import './whatWeDo.scss';
import bucketBGRectangle from '../../assets/img/companies/rectangle-bucket-bg.svg';
import SectionTitle from '../../components/common/SectionTitle';
import TextDescription from '../../components/common/TextDescription';
import Span from '../../components/pagesComponents/CaseStudy/Span';
import { FaReact, FaNode, FaJava, FaChrome } from 'react-icons/fa';
import {
    SiRubyonrails,
    SiPython,
    SiIos,
    SiSwift,
    SiAmazon,
    SiKotlin,
    SiMicrosoftazure,
    SiTypescript,
    SiSpring,
    SiRedux,
    SiApachekafka,
    SiDocker,
} from 'react-icons/si';
import { DiJavascript1, DiAndroid, DiRuby, DiDjango } from 'react-icons/di';
import { TIconsArray, TechnologiesTitle } from '../../types/types';
import { Link } from 'gatsby';
import FixedButton from '../../components/common/FixedButton';
import SEO from '../../components/SEO';
import Technologies from '../../components/pagesComponents/MainPage/Technologies';
import Button from '../../components/common/Button';
import arrowRight from '../../assets/img/arr-right.svg';
import arrowDown from '../../assets/img/arr-down.png';

const WhatWeDo: FC = () => {
    const Languages: TIconsArray = [
        { id: 0, icon: <FaJava />, link: 'https://www.java.com/' },
        {
            id: 1,
            icon: <DiJavascript1 />,
            link: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript',
        },
        { id: 2, icon: <DiRuby />, link: 'https://www.ruby-lang.org/en/' },
        { id: 3, icon: <SiPython />, link: 'https://www.python.org' },
        { id: 4, icon: <SiTypescript />, link: 'https://www.typescriptlang.org/' },
        { id: 5, icon: <SiSwift />, link: 'https://swift.org/' },
        { id: 6, icon: <SiKotlin />, link: 'https://kotlinlang.org/' },
    ];

    const Frameworks: TIconsArray = [
        { id: 0, icon: <SiSpring />, link: 'https://spring.io/' },
        {
            id: 1,
            icon: <DiDjango />,
            link: 'https://www.djangoproject.com/',
        },
        { id: 2, icon: <SiRubyonrails />, link: 'https://rubyonrails.org/' },
        { id: 3, icon: <FaReact />, link: 'https://reactjs.org/' },
        { id: 4, icon: <SiRedux />, link: 'https://redux.js.org/' },
        { id: 5, icon: <FaNode />, link: 'https://nodejs.org/en/' },
    ];

    const Platforms: TIconsArray = [
        { id: 0, icon: <SiIos />, link: 'https://developer.apple.com/ios/' },
        {
            id: 1,
            icon: <FaChrome />,
            link:
                'https://www.google.com/chrome/?brand=BNSD&gclid=Cj0KCQjwrsGCBhD1ARIsALILBYorkYjg26A_DilD5gkwp-tJ_F8GE3nqzoEth0qF62X1vnWf6sBol4YaAphKEALw_wcB&gclsrc=aw.ds',
        },
        {
            id: 2,
            icon: <DiAndroid />,
            link: 'https://www.android.com/',
        },
    ];

    const Clouds: TIconsArray = [
        {
            id: 0,
            icon: <SiAmazon />,
            link:
                'https://aws.amazon.com/free/?all-free-tier.sort-by=item.additionalFields.SortRank&all-free-tier.sort-order=asc&awsf.Free%20Tier%20Types=*posts&awsf.Free%20Tier%20Categories=categories%23compute&trk=ps_a134p000006padNAAQ&trkCampaign=acq_paid_search_brand&sc_channel=PS&sc_campaign=acquisition_EEM&sc_publisher=Google&sc_category=Cloud%20Computing&sc_country=EEM&sc_geo=EMEA&sc_outcome=acq&sc_detail=amazon%20cloud&sc_content=Cloud%20Hosting_e&sc_matchtype=e&sc_segment=495112466333&sc_medium=ACQ-P|PS-GO|Brand|Desktop|SU|Cloud%20Computing|Solution|EEM|EN|Text|xx|Non-EU&s_kwcid=AL!4422!3!495112466333!e!!g!!amazon%20cloud&ef_id=Cj0KCQjwi7yCBhDJARIsAMWFScNdrYUGb5qBhf8mE2j9DnP05Pp2485y7-GI8Z0fLDoRVxFQ4QRI2n0aArOPEALw_wcB:G:s&s_kwcid=AL!4422!3!495112466333!e!!g!!amazon%20cloud',
        },
        {
            id: 1,
            icon: <SiMicrosoftazure />,
            link:
                'https://azure.microsoft.com/en-us/free/search/?&ef_id=Cj0KCQjwi7yCBhDJARIsAMWFScMq3t57TnFA2RgQLthup-d2RQFl55OsPfbmZQRloqUPNNgEe8opzDcaAjU3EALw_wcB:G:s&OCID=AID2100648_SEM_Cj0KCQjwi7yCBhDJARIsAMWFScMq3t57TnFA2RgQLthup-d2RQFl55OsPfbmZQRloqUPNNgEe8opzDcaAjU3EALw_wcB:G:s',
        },
    ];

    const Other: TIconsArray = [
        {
            id: 0,
            icon: <SiApachekafka />,
            link: 'https://kafka.apache.org/',
        },
        {
            id: 1,
            icon: <SiDocker />,
            link: 'https://www.docker.com/',
        },
    ];

    const [languagesArr, setLanguagesArr] = useState<boolean>(true);
    const [frameworksArr, setFrameworksArr] = useState<boolean>(false);
    const [platformsArr, setPlatformsArr] = useState<boolean>(false);
    const [cloudArr, setCloudArr] = useState<boolean>(false);
    const [otherArr, setOtherArr] = useState<boolean>(false);

    const showLanguagesArray = (): void => {
        setLanguagesArr(true);
        setFrameworksArr(false);
        setPlatformsArr(false);
        setCloudArr(false);
        setOtherArr(false);
    };

    const showFrameworksArr = (): void => {
        setLanguagesArr(false);
        setFrameworksArr(true);
        setPlatformsArr(false);
        setCloudArr(false);
        setOtherArr(false);
    };

    const showPlatformsArr = (): void => {
        setLanguagesArr(false);
        setFrameworksArr(false);
        setPlatformsArr(true);
        setCloudArr(false);
        setOtherArr(false);
    };

    const showCloudArr = (): void => {
        setLanguagesArr(false);
        setFrameworksArr(false);
        setPlatformsArr(false);
        setCloudArr(true);
        setOtherArr(false);
    };
    const showOtherArr = (): void => {
        setLanguagesArr(false);
        setFrameworksArr(false);
        setPlatformsArr(false);
        setCloudArr(false);
        setOtherArr(true);
    };

    let offsetValue: number = 200;

    useEffect(() => {
        const handleOffset = (): void => {
            if (window.innerWidth <= 690) {
                offsetValue = 320;
            }

            if (window.innerWidth <= 500) {
                offsetValue = 410;
            }

            console.log(window.innerWidth);
        };
    });

    const technologiesTitle: TechnologiesTitle = [
        { id: 0, title: 'Languages', value: languagesArr, function: showLanguagesArray },
        {
            id: 1,
            title: 'Frameworks & libraries',
            value: frameworksArr,
            function: showFrameworksArr,
        },
        { id: 2, title: 'Platforms', value: platformsArr, function: showPlatformsArr },
        { id: 3, title: 'Cloud', value: cloudArr, function: showCloudArr },
        { id: 4, title: 'Other', value: otherArr, function: showOtherArr },
    ];

    return (
        <Layouts>
            <SEO
                pageTitle="Blackbird Lab - What We Do"
                pageDescription="Blackbird Lab is a software development company that provides effective solutions to clients worldwide. Since 2018, we have been on a mission to deliver quality products..."
                pageImage="https://blackbird-image-cdn.s3.eu-central-1.amazonaws.com/what-we-do/what-we-do.png"
            />
            <PageHeader
                offset={offsetValue}
                id="what-we-do"
                title="What We Do"
                subtitle="Discover"
                bgType="dots"
                btnPosition="moved"
            />
            <section className="what-we-do">
                <div className="what-we-do__description  what-we-do__description--mt">
                    <TextDescription>
                        Our expertise lies in product and platform engineering. We partner with our
                        clients to tackle their most intricate business challenges, crafting
                        technology solutions that redefine entire industries and revolutionize how
                        people experience the world. Together, we'll convert your ideas into
                        cutting-edge intellectual property, bespoke digital products, invaluable
                        insights, and seamless automation.
                    </TextDescription>
                </div>
                <div className="what-we-do__description  what-we-do__description--mt">
                    <SectionTitle type="primary" title="Retail and E-commerce" />
                    <TextDescription>
                        Our clients’ products are heavily used by such companies like Walmart, The
                        Home Depot, CVS Pharmacy in the USA and Canada. It’s a joy to be a part of
                        their success.
                    </TextDescription>
                </div>

                <div className="what-we-do__companies">
                    <div className="what-we-do__company">
                        <Link to="/walmart-case-study/">
                            <SmallCompanyContainer
                                img={walmart}
                                description="Increasing Walmart weekly ads conversion with a custom web app for Ad Tech
                                company"
                                hide="hide"
                                size="primary"
                                marginValue="mr-15"
                            />
                        </Link>
                    </div>
                    <div className="what-we-do__company">
                        <Link to="/statflo-case-study/">
                            <SmallCompanyContainer
                                type="secondary"
                                img={statflo}
                                description="Entirely rebuilt 1:1 SMS outreach SaaS product for extra profitable retail platform"
                                hide="hide"
                                logoType="secondary"
                                size="primary"
                            />
                        </Link>
                    </div>
                </div>
                <div className="what-we-do__description what-we-do__description--tablet">
                    <SectionTitle type="primary" title="Construction" />
                    <TextDescription>
                        The construction industry is well-known to us: from creating a fully custom
                        ERP system for a large general engineering contractor to working on a
                        pioneering AR-based product for both HoloLens versions and its web
                        dashboard.
                    </TextDescription>
                </div>
                <div className="what-we-do__company-card">
                    <Link to="/spectar-case-study/">
                        <LargeCompanyContainer
                            typeBG="bg-main"
                            img={spectar}
                            description="Building a pioneering AR product for construction"
                            label="CASE STUDY"
                            hashtags="Microservice architecture, Microsoft HoloLens 2, REST API, Web development, +13 more"
                            hide="hide"
                            size="large"
                        />
                    </Link>
                </div>
                <div className="what-we-do__description-wrapper" id="what-we-do">
                    <div className="what-we-do__description">
                        <SectionTitle type="primary" title="Finance and ERP" />
                        <TextDescription>
                            It took a few years of close-up work with large U.S. general engineering
                            contractor to investigate, analyze, optimize their internal processes,
                            and finally built an ERP system which put them into the growth stage.
                        </TextDescription>
                    </div>
                    <div className="what-we-do__description what-we-do__description--mt">
                        <SectionTitle type="primary" title="Media" />
                        <TextDescription>
                            We helped to build products that are made to reach, interact, and
                            monetize large audiences. 1:1 SMS outreach products, chatbots,
                            proprietary software products for internal marketing teams. We did it
                            all.
                        </TextDescription>
                    </div>
                </div>
                <div className="what-we-do__button">
                    <Link to="/blog/studies/">
                        <Button icon={arrowRight} type="primary" desc="See all">
                            See all
                        </Button>
                    </Link>
                </div>
            </section>
            <section className="what-we-do">
                <SectionTitle type="lighter" title="Technologies" />
                <TextDescription type="mb-30">
                    Possession of an extensive range of technologies and knowledge of many platforms
                    allows our team successfully implements complex projects and achieves
                    significant business goals.
                </TextDescription>
                <div className="web-development__wrapper">
                    <div id="services" className="web-development__body">
                        <div className="web-development__list">
                            {technologiesTitle.map((tech) => (
                                <div key={tech.id} onClick={tech.function}>
                                    <Span subtype={tech.value ? 'active' : ''} type="link">
                                        {tech.title}
                                    </Span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="web-development__border" />
                    <div className="web-development__technologies">
                        {languagesArr && (
                            <TechIcon
                                large="large"
                                iconsArray={Languages}
                                img={bucketBGRectangle}
                                label=""
                                show="hide"
                                margin="center"
                                showBucket={false}
                                size="ml"
                                type="primary"
                                hideIMG="hide"
                            />
                        )}
                        {frameworksArr && (
                            <TechIcon
                                large="large"
                                iconsArray={Frameworks}
                                img={bucketBGRectangle}
                                label=""
                                show="hide"
                                margin="center"
                                showBucket={false}
                                size="ml"
                                type="primary"
                                hideIMG="hide"
                            />
                        )}
                        {platformsArr && (
                            <TechIcon
                                large="large"
                                iconsArray={Platforms}
                                img={bucketBGRectangle}
                                label=""
                                show="hide"
                                margin="center"
                                showBucket={false}
                                size="ml"
                                type="primary"
                                hideIMG="hide"
                            />
                        )}

                        {cloudArr && (
                            <TechIcon
                                large="large"
                                iconsArray={Clouds}
                                img={bucketBGRectangle}
                                label=""
                                show="hide"
                                margin="center"
                                showBucket={false}
                                size="ml"
                                type="primary"
                                hideIMG="hide"
                            />
                        )}

                        {otherArr && (
                            <TechIcon
                                large="large"
                                iconsArray={Other}
                                img={bucketBGRectangle}
                                label=""
                                show="hide"
                                margin="center"
                                showBucket={false}
                                size="ml"
                                type="primary"
                                hideIMG="hide"
                            />
                        )}
                    </div>
                </div>
                <div className="what-we-do__form">
                    <div data-testid="technologies" className="tech">
                        <div className="tech__info">
                            <SectionTitle
                                type="relevant"
                                title="Cannot find relevant technology stack or similar project from your industry?"
                            />
                            <p className="tech__desc">
                                It must be our rigorous
                                <Link to="/how-we-work/intellectual-property/">
                                    <span className="tech__protection">
                                        intellectual property protection effort.
                                    </span>
                                </Link>
                                Just tell us what you need and get that information in less than 24
                                hours.
                            </p>
                            <div className="what-we-do__btn">
                                <Link className="link" to="/contact-us">
                                    <Button icon={arrowRight} type="primary" desc="Tell us">
                                        Tell us
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FixedButton />
        </Layouts>
    );
};

export default WhatWeDo;
