import React, { FC } from 'react';
import './Span.scss';
import { ISpanProps } from '../../../../types/types';

const Span: FC<ISpanProps> = (props) => {
    return (
        <span data-testid="span" className={`span span--${props.type} span--${props.subtype}`}>
            {props.children}
        </span>
    );
};

export default Span;
